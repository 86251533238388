import { useRouter } from "next/router";
import { useMutation } from "react-query";
import React from "react";
import { Grid, Typography } from "@material-ui/core";

import { availableCountries } from "src/const/availableCountriesOfBusinessRegistration";
import { Form, IFormProps } from "src/components/Form";
import { validationBuilder } from "src/components/Form/validation/validationBuilder";
import { ESectionLayout } from "src/components/Layout/Section/Layouts";
import { authenticatedSSPropsWithPreload } from "src/utilities/authenticatedSSProps";
import { EInputType } from "src/components/Form/FormSection/FormGroup/FormRow/FormField";
import { validateEmail } from "src/components/Form/validation/validateEmail";
import { mutateToPath } from "src/utilities/mutateFn";
import { IApplication } from "types/gateway";
import {
  convertAlpha3ToAlpha2,
  getCountryName,
} from "src/utilities/i18n/countries";
import { ISelectOption } from "src/components/Input/SelectInput";
import { CountryFlag } from "src/components/CountryFlag";
import { TCountryCode } from "types/countryData";
import { ESupportedCountries } from "types/onboarding/enums/ESupportedCountries.enum";

interface IFormFields {
  email: string;
  name: string;
  country: string;
}

interface IGetStartedPageProps {
  countryOptions: ISelectOption[];
}

const OTHER_COUNTRY_VALUE = "OTHER";

export default function GetStartedPage({
  countryOptions,
}: IGetStartedPageProps): JSX.Element {
  const router = useRouter();
  const { mutateAsync } = useMutation<IApplication, unknown, IFormFields>(
    mutateToPath<IApplication, IFormFields>(
      "/vision/self-onboarding/welcome-email"
    )
  );

  const formConfig: IFormProps<IFormFields>["formConfig"] = [
    {
      groups: [
        {
          footerJsx: (
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                You&apos;ll be sent a link to your Application for easy viewing
                anywhere, anytime.
              </Typography>
            </Grid>
          ),
          visualGroup: true,
          rows: [
            {
              fields: [
                {
                  id: "name",
                  label: "Name",
                  type: EInputType.TEXT,
                },
                {
                  id: "country",
                  label: "Country",
                  type: EInputType.SELECT,
                  data: countryOptions,
                  inputStartIcon: (value: string) => {
                    return (
                      <CountryFlag
                        code={
                          (convertAlpha3ToAlpha2(value) as TCountryCode) ||
                          value
                        }
                        altText={value}
                      />
                    );
                  },
                },
              ],
            },
            {
              fields: [
                {
                  id: "email",
                  label: "Email",
                  type: EInputType.TEXT,
                  placeholder: "email@example.com",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const initialValues: IFormProps<IFormFields>["initialValues"] = {
    name: "",
    email: "",
    country: ESupportedCountries.AUS,
  };

  const validate: IFormProps<IFormFields>["validate"] = validationBuilder({
    name: {
      type: EInputType.TEXT,
      required: true,
    },
    email: {
      type: EInputType.EMAIL,
      required: true,
      validationTests: [validateEmail],
    },
    country: {
      type: EInputType.SELECT,
      required: true,
    },
  });

  async function handleSubmit(values: IFormFields) {
    const application = await mutateAsync(values);
    if (values.country === OTHER_COUNTRY_VALUE) {
      await router.push(`/onboarding/interested`);
    } else {
      await router.push(`/onboarding/${application.id}`);
    }
  }

  return (
    <Form<IFormFields>
      formConfig={formConfig}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      submitLabel="Next"
    />
  );
}

const countryOptions: ISelectOption[] = availableCountries
  .map((code) => {
    return {
      value: code,
      label: getCountryName(code),
    };
  })
  .sort((currentCountryData, nextCountryData) =>
    (currentCountryData.label || "").localeCompare(nextCountryData.label || "")
  );

countryOptions.push({
  value: OTHER_COUNTRY_VALUE,
  label: "Others",
});

export const getServerSideProps = authenticatedSSPropsWithPreload({
  pageProps: {
    title: "Start Now",
    subLayoutProps: {
      title: "Start Now",
      subtitle: "Enter your details to get started.",
      displayStepper: false,
    },
    countryOptions,
  },
  layout: ESectionLayout.ApplicationForm,
});
