import type { ReactElement } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Image from "next/image";

import { ECountryIcons } from "types/enums/ECountryIcons";
import { TCountryCode } from "types/countryData";
import { remToPx } from "src/utilities/remToPx";

const useStyles = makeStyles({
  root: {
    height: "0.875rem",
    width: "1.125rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export interface ICountryFlagProps {
  size?: EFlagSize;
  code: TCountryCode;
  altText: string;
}
export enum EFlagSize {
  SM = 1,
  MD = 1.125,
  LG = 1.5,
}

export function CountryFlag({
  code,
  size = EFlagSize.MD,
  altText,
}: ICountryFlagProps): ReactElement {
  const styles = useStyles({ size });
  const src = ECountryIcons[code as keyof typeof ECountryIcons];

  if (!src) {
    return <></>;
  }

  return (
    <Box className={styles.root}>
      <Image
        layout="fixed"
        src={src}
        height={remToPx(0.875)}
        width={remToPx(1.125)}
        alt={altText}
      />
    </Box>
  );
}
