import i18nCountries from "i18n-iso-countries";
import i18nEnglish from "i18n-iso-countries/langs/en.json";

/*  https://www.npmjs.com/package/i18n-iso-countries
 *  If you use the package in a browser environment, you have to register the languages you want to use to minimize the file size.
 */
i18nCountries.registerLocale(i18nEnglish);

export function getCountryName(alpha3: string): string {
  return i18nCountries.getName(alpha3, "en", { select: "official" });
}

export function convertAlpha3ToAlpha2(alpha3: string): string {
  return i18nCountries.toAlpha2(alpha3);
}

export function getAllCountries(lang: string): { [alpha3: string]: string } {
  return Object.fromEntries(
    Object.entries(i18nCountries.getNames(lang)).map(([alpha2, simple]) => [
      i18nCountries.toAlpha3(alpha2),
      simple,
    ])
  );
}

export const { getName } = i18nCountries;
