export enum ECountryIcons {
  AD = "/countryFlagsPng/AD.png",
  AE = "/countryFlagsPng/AE.png",
  AF = "/countryFlagsPng/AF.png",
  AG = "/countryFlagsPng/AG.png",
  AI = "/countryFlagsPng/AI.png",
  AL = "/countryFlagsPng/AL.png",
  AM = "/countryFlagsPng/AM.png",
  AN = "/countryFlagsPng/AN.png",
  AO = "/countryFlagsPng/AO.png",
  AR = "/countryFlagsPng/AR.png",
  AS = "/countryFlagsPng/AS.png",
  AT = "/countryFlagsPng/AT.png",
  AU = "/countryFlagsPng/AU.png",
  AW = "/countryFlagsPng/AW.png",
  AX = "/countryFlagsPng/AX.png",
  AZ = "/countryFlagsPng/AZ.png",
  BA = "/countryFlagsPng/BA.png",
  BB = "/countryFlagsPng/BB.png",
  BD = "/countryFlagsPng/BD.png",
  BE = "/countryFlagsPng/BE.png",
  BF = "/countryFlagsPng/BF.png",
  BG = "/countryFlagsPng/BG.png",
  BH = "/countryFlagsPng/BH.png",
  BI = "/countryFlagsPng/BI.png",
  BJ = "/countryFlagsPng/BJ.png",
  BL = "/countryFlagsPng/BL.png",
  BM = "/countryFlagsPng/BM.png",
  BN = "/countryFlagsPng/BN.png",
  BO = "/countryFlagsPng/BO.png",
  BQ = "/countryFlagsPng/BQ.png",
  BR = "/countryFlagsPng/BR.png",
  BS = "/countryFlagsPng/BS.png",
  BT = "/countryFlagsPng/BT.png",
  BW = "/countryFlagsPng/BW.png",
  BY = "/countryFlagsPng/BY.png",
  BZ = "/countryFlagsPng/BZ.png",
  CA = "/countryFlagsPng/CA.png",
  CC = "/countryFlagsPng/CC.png",
  CD = "/countryFlagsPng/CD.png",
  CF = "/countryFlagsPng/CF.png",
  CG = "/countryFlagsPng/CG.png",
  CH = "/countryFlagsPng/CH.png",
  CI = "/countryFlagsPng/CI.png",
  CK = "/countryFlagsPng/CK.png",
  CL = "/countryFlagsPng/CL.png",
  CM = "/countryFlagsPng/CM.png",
  CN = "/countryFlagsPng/CN.png",
  CO = "/countryFlagsPng/CO.png",
  CR = "/countryFlagsPng/CR.png",
  CU = "/countryFlagsPng/CU.png",
  CX = "/countryFlagsPng/CX.png",
  CV = "/countryFlagsPng/CV.png",
  CW = "/countryFlagsPng/CW.png",
  CY = "/countryFlagsPng/CY.png",
  CZ = "/countryFlagsPng/CZ.png",
  DE = "/countryFlagsPng/DE.png",
  DJ = "/countryFlagsPng/DJ.png",
  DK = "/countryFlagsPng/DK.png",
  DM = "/countryFlagsPng/DM.png",
  DO = "/countryFlagsPng/DO.png",
  DZ = "/countryFlagsPng/DZ.png",
  EC = "/countryFlagsPng/EC.png",
  EE = "/countryFlagsPng/EE.png",
  EG = "/countryFlagsPng/EG.png",
  EH = "/countryFlagsPng/EH.png",
  ER = "/countryFlagsPng/ER.png",
  ES = "/countryFlagsPng/ES.png",
  ET = "/countryFlagsPng/ET.png",
  EU = "/countryFlagsPng/EU.png",
  FI = "/countryFlagsPng/FI.png",
  FJ = "/countryFlagsPng/FJ.png",
  FK = "/countryFlagsPng/FK.png",
  FM = "/countryFlagsPng/FM.png",
  FO = "/countryFlagsPng/FO.png",
  FR = "/countryFlagsPng/FR.png",
  GA = "/countryFlagsPng/GA.png",
  GB = "/countryFlagsPng/GB.png",
  GD = "/countryFlagsPng/GD.png",
  GE = "/countryFlagsPng/GE.png",
  GF = "/countryFlagsPng/GF.png",
  GG = "/countryFlagsPng/GG.png",
  GH = "/countryFlagsPng/GH.png",
  GI = "/countryFlagsPng/GI.png",
  GL = "/countryFlagsPng/GL.png",
  GM = "/countryFlagsPng/GM.png",
  GN = "/countryFlagsPng/GN.png",
  GP = "/countryFlagsPng/GP.png",
  GQ = "/countryFlagsPng/GQ.png",
  GR = "/countryFlagsPng/GR.png",
  GT = "/countryFlagsPng/GT.png",
  GU = "/countryFlagsPng/GU.png",
  GW = "/countryFlagsPng/GW.png",
  GY = "/countryFlagsPng/GY.png",
  HK = "/countryFlagsPng/HK.png",
  HN = "/countryFlagsPng/HN.png",
  HR = "/countryFlagsPng/HR.png",
  HT = "/countryFlagsPng/HT.png",
  HU = "/countryFlagsPng/HU.png",
  ID = "/countryFlagsPng/ID.png",
  IE = "/countryFlagsPng/IE.png",
  IL = "/countryFlagsPng/IL.png",
  IM = "/countryFlagsPng/IM.png",
  IN = "/countryFlagsPng/IN.png",
  IO = "/countryFlagsPng/IO.png",
  IQ = "/countryFlagsPng/IQ.png",
  IR = "/countryFlagsPng/IR.png",
  IS = "/countryFlagsPng/IS.png",
  IT = "/countryFlagsPng/IT.png",
  JE = "/countryFlagsPng/JE.png",
  JM = "/countryFlagsPng/JM.png",
  JO = "/countryFlagsPng/JO.png",
  JP = "/countryFlagsPng/JP.png",
  KE = "/countryFlagsPng/KE.png",
  KG = "/countryFlagsPng/KG.png",
  KH = "/countryFlagsPng/KH.png",
  KI = "/countryFlagsPng/KI.png",
  KM = "/countryFlagsPng/KM.png",
  KN = "/countryFlagsPng/KN.png",
  KP = "/countryFlagsPng/KP.png",
  KR = "/countryFlagsPng/KR.png",
  KW = "/countryFlagsPng/KW.png",
  KY = "/countryFlagsPng/KY.png",
  KZ = "/countryFlagsPng/KZ.png",
  LA = "/countryFlagsPng/LA.png",
  LB = "/countryFlagsPng/LB.png",
  LC = "/countryFlagsPng/LC.png",
  LI = "/countryFlagsPng/LI.png",
  LK = "/countryFlagsPng/LK.png",
  LR = "/countryFlagsPng/LR.png",
  LS = "/countryFlagsPng/LS.png",
  LT = "/countryFlagsPng/LT.png",
  LU = "/countryFlagsPng/LU.png",
  LV = "/countryFlagsPng/LV.png",
  LY = "/countryFlagsPng/LY.png",
  MA = "/countryFlagsPng/MA.png",
  MC = "/countryFlagsPng/MC.png",
  MD = "/countryFlagsPng/MD.png",
  ME = "/countryFlagsPng/ME.png",
  MF = "/countryFlagsPng/MF.png",
  MG = "/countryFlagsPng/MG.png",
  MH = "/countryFlagsPng/MH.png",
  MK = "/countryFlagsPng/MK.png",
  ML = "/countryFlagsPng/ML.png",
  MM = "/countryFlagsPng/MM.png",
  MN = "/countryFlagsPng/MN.png",
  MO = "/countryFlagsPng/MO.png",
  MP = "/countryFlagsPng/MP.png",
  MQ = "/countryFlagsPng/MQ.png",
  MR = "/countryFlagsPng/MR.png",
  MS = "/countryFlagsPng/MS.png",
  MT = "/countryFlagsPng/MT.png",
  MU = "/countryFlagsPng/MU.png",
  MV = "/countryFlagsPng/MV.png",
  MW = "/countryFlagsPng/MW.png",
  MX = "/countryFlagsPng/MX.png",
  MY = "/countryFlagsPng/MY.png",
  MZ = "/countryFlagsPng/MZ.png",
  NA = "/countryFlagsPng/NA.png",
  NC = "/countryFlagsPng/NC.png",
  NE = "/countryFlagsPng/NE.png",
  NF = "/countryFlagsPng/NF.png",
  NG = "/countryFlagsPng/NG.png",
  NI = "/countryFlagsPng/NI.png",
  NL = "/countryFlagsPng/NL.png",
  NO = "/countryFlagsPng/NO.png",
  NP = "/countryFlagsPng/NP.png",
  NR = "/countryFlagsPng/NR.png",
  NU = "/countryFlagsPng/NU.png",
  NZ = "/countryFlagsPng/NZ.png",
  OM = "/countryFlagsPng/OM.png",
  PA = "/countryFlagsPng/PA.png",
  PE = "/countryFlagsPng/PE.png",
  PF = "/countryFlagsPng/PF.png",
  PG = "/countryFlagsPng/PG.png",
  PH = "/countryFlagsPng/PH.png",
  PK = "/countryFlagsPng/PK.png",
  PL = "/countryFlagsPng/PL.png",
  PM = "/countryFlagsPng/PM.png",
  PR = "/countryFlagsPng/PR.png",
  PS = "/countryFlagsPng/PS.png",
  PT = "/countryFlagsPng/PT.png",
  PW = "/countryFlagsPng/PW.png",
  PY = "/countryFlagsPng/PY.png",
  QA = "/countryFlagsPng/QA.png",
  RE = "/countryFlagsPng/RE.png",
  RO = "/countryFlagsPng/RO.png",
  RS = "/countryFlagsPng/RS.png",
  RU = "/countryFlagsPng/RU.png",
  RW = "/countryFlagsPng/RW.png",
  SA = "/countryFlagsPng/SA.png",
  SB = "/countryFlagsPng/SB.png",
  SC = "/countryFlagsPng/SC.png",
  SD = "/countryFlagsPng/SD.png",
  SE = "/countryFlagsPng/SE.png",
  SG = "/countryFlagsPng/SG.png",
  SH = "/countryFlagsPng/SH.png",
  SI = "/countryFlagsPng/SI.png",
  SJ = "/countryFlagsPng/SJ.png",
  SK = "/countryFlagsPng/SK.png",
  SL = "/countryFlagsPng/SL.png",
  SM = "/countryFlagsPng/SM.png",
  SN = "/countryFlagsPng/SN.png",
  SO = "/countryFlagsPng/SO.png",
  SR = "/countryFlagsPng/SR.png",
  SS = "/countryFlagsPng/SS.png",
  ST = "/countryFlagsPng/ST.png",
  SV = "/countryFlagsPng/SV.png",
  SX = "/countryFlagsPng/SX.png",
  SY = "/countryFlagsPng/SY.png",
  SZ = "/countryFlagsPng/SZ.png",
  TC = "/countryFlagsPng/TC.png",
  TD = "/countryFlagsPng/TD.png",
  TG = "/countryFlagsPng/TG.png",
  TH = "/countryFlagsPng/TH.png",
  TJ = "/countryFlagsPng/TJ.png",
  TK = "/countryFlagsPng/TK.png",
  TL = "/countryFlagsPng/TL.png",
  TM = "/countryFlagsPng/TM.png",
  TN = "/countryFlagsPng/TN.png",
  TO = "/countryFlagsPng/TO.png",
  TR = "/countryFlagsPng/TR.png",
  TT = "/countryFlagsPng/TT.png",
  TV = "/countryFlagsPng/TV.png",
  TW = "/countryFlagsPng/TW.png",
  TZ = "/countryFlagsPng/TZ.png",
  UA = "/countryFlagsPng/UA.png",
  UG = "/countryFlagsPng/UG.png",
  US = "/countryFlagsPng/US.png",
  UY = "/countryFlagsPng/UY.png",
  UZ = "/countryFlagsPng/UZ.png",
  VA = "/countryFlagsPng/VA.png",
  VC = "/countryFlagsPng/VC.png",
  VE = "/countryFlagsPng/VE.png",
  VG = "/countryFlagsPng/VG.png",
  VI = "/countryFlagsPng/VI.png",
  VN = "/countryFlagsPng/VN.png",
  VU = "/countryFlagsPng/VU.png",
  WF = "/countryFlagsPng/WF.png",
  WS = "/countryFlagsPng/WS.png",
  XK = "/countryFlagsPng/XK.png",
  YE = "/countryFlagsPng/YE.png",
  YT = "/countryFlagsPng/YT.png",
  ZA = "/countryFlagsPng/ZA.png",
  ZM = "/countryFlagsPng/ZM.png",
  ZW = "/countryFlagsPng/ZW.png",
  OTHER = "/countryFlagsPng/OTHER.png",
}
