import type { FormikValues } from "formik";
import isEmail from "validator/lib/isEmail";

import { TValidatorTestFunc } from "./validationBuilder";

type TReturn<Values> = ReturnType<TValidatorTestFunc<string, Values>>;

export function validateEmail<Values extends FormikValues = FormikValues>(
  value: string
): TReturn<Values> {
  return isEmail(value) ? null : "Invalid format";
}
